import styled from 'styled-components'

export const StyledButtonDownloadFileContainer = styled.div`
  max-width: 200px;
  margin: 0 auto;
  &.a-left {
    float: left;
  }
  &.a-right {
    float: right;
  }
`
export const StyledButtonDownloadFile = styled.button`
  width: 100%;
  min-width: 147px;
  border-radius: 4px;
  border: solid 1px #a7a5a5;
  padding: 5px;
  cursor: pointer;
  background-color: #fff;
  font-size: 11px;

  :hover {
    background-color: #dfdfdffa;
  }

  :focus {
    outline: none;
  }

  :disabled {
    background-color: #d0d0d0;
    border: none;
  }

  @media (max-width: 360px) {
    min-width: max-content;
  }

  @media all and (max-width: 380px) {
    font-size: 10px;
  }
`
export const StyledButtonDownloadFileImg = styled.img`
  margin: 5px;
  width: 25px;
  height: 25px;

  @media all and (max-width: 380px) {
    width: 18px;
    height: 18px;
  }
`
