// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../../components/SEO'
import InventoryContainer from '../../../containers/InventoryContainer'

import LayoutMain from '../../../layout'

const Rewards: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Inventario" />
      <WithPrivateRoute component={InventoryContainer} />
    </LayoutMain>
  )
}

export default Rewards
