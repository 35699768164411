import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import {
  StyledDatePickerRange,
  StyledDatePickerRangeContainer
} from './styledComponents'
import moment, { Moment } from 'moment'
import { RightOutlined } from '@ant-design/icons'
import calendar from '../../../data/assets/icons/calendar.svg'

export interface dateValueInterface {
  renderFrom: string | undefined
  renderTo: string | undefined
}

type props = {
  format: string
  disabledDate?: (current: Moment) => boolean
  name: string
  label?: string
  id: string
  dataTestId: string
  changeDate?: (dates: any) => void
  dateValue?: dateValueInterface
}

export const DatePickerRange: React.FC<props> = ({
  format,
  disabledDate,
  name,
  id,
  label,
  dataTestId,
  changeDate,
  dateValue
}) => {
  const [displayedValues, setDisplaValues] = useState<dateValueInterface>()

  useEffect(() => {
    setDisplaValues({
      renderFrom: dateValue?.renderFrom ? dateValue.renderFrom : '',
      renderTo: dateValue?.renderTo ? dateValue.renderTo : ''
    })
  }, [dateValue])
  return (
    <Form.Item name={name} label={label} id={id} data-test-id={dataTestId}>
      <StyledDatePickerRangeContainer>
        <StyledDatePickerRange
          format={format}
          disabledDate={disabledDate}
          separator={<RightOutlined />}
          suffixIcon={<img src={calendar} alt={'date-picker-custom'} />}
          onChange={changeDate}
          value={
            displayedValues?.renderFrom
              ? [
                  moment(displayedValues?.renderFrom),
                  moment(displayedValues?.renderTo)
                ]
              : null
          }
        />
      </StyledDatePickerRangeContainer>
    </Form.Item>
  )
}
