import styled from 'styled-components'

interface StyledProps {
  styles: StyledTextRedProps
}

interface StyledTextRedProps {
  isRed: boolean
}

export const StyledConatnerReportByMonthsPDF = styled.div`
  font-size: 9px;

  padding: 12px;
`

export const StyledContainerTable = styled.div`
  padding: 12px;
`

export const StyledContainerTableDetail = styled.div`
  padding: 40px 12px;
`

export const StyledTD = styled.td`
  padding: 5px;
  background-color: #d9e1f2;
`

export const StyledTDOdd = styled.td`
  padding: 5px;
`

export const StyledTRDetail = styled.tr<StyledProps>`
  border: 1px solid black;
  color: ${(props) => (props.styles.isRed ? 'red' : 'black')};
`
