import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import {
  StyledButtonDownloadFile,
  StyledButtonDownloadFileContainer,
  StyledButtonDownloadFileImg
} from './styledComponents'
import { Spin } from 'antd'

type props = {
  icon: any
  text: string
  onClick: () => void
  disabled?: boolean
  loading?: boolean
  alignment?: string
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export const ButtonDownloadFile: React.FC<props> = ({
  icon,
  text,
  onClick,
  disabled = false,
  loading = false,
  alignment
}) => {
  return (
    <StyledButtonDownloadFileContainer className={alignment}>
      <StyledButtonDownloadFile onClick={onClick} disabled={disabled}>
        {loading && (
          <Spin indicator={antIcon} data-testid="loading-indicator" />
        )}
        <StyledButtonDownloadFileImg src={icon} alt="Descargar archivo" />
        {text}
      </StyledButtonDownloadFile>
    </StyledButtonDownloadFileContainer>
  )
}
