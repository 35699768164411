import * as XLSX from 'xlsx-js-style'

interface Api {
  content: any
}

const StyleCell = (textYellow = false) => ({
  font: {
    bold: true,
    color: {
      rgb: textYellow ? 'ffc600' : 'FFFFFF'
    }
  },
  fill: {
    fgColor: { rgb: '00206C' }
  }
})

export const printInventoryStockExcel = ({ content }: Api) => {
  const ws = XLSX.utils.table_to_sheet(content(), { raw: true })
  ws['A1'].s = StyleCell(true)
  ws['A2'].s = StyleCell()
  ws['A3'].s = StyleCell()
  ws['A4'].s = StyleCell()
  ws['A5'].s = StyleCell()
  ws['A7'].s = StyleCell()
  ws['B7'].s = StyleCell()
  ws['C7'].s = StyleCell()
  ws['D7'].s = StyleCell()
  ws['E7'].s = StyleCell()
  ws['F7'].s = StyleCell()
  ws['G7'].s = StyleCell()
  ws['H7'].s = StyleCell()
  const wb = { Sheets: { InventoryStock: ws }, SheetNames: ['InventoryStock'] }
  XLSX.writeFile(wb, 'InventarioMiNegocio.xlsx')
}
