import styled from 'styled-components'

export const StyledFilterContent = styled.div`
  width: 100%;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
`

export const StyledReturnButtonContent = styled.div`
  width: 37%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  .anticon {
    margin-right: 9px;
  }
`

export const StyledDatePickerContent = styled.div`
  width: 63%;
  padding-top: 23px;
`
export const StyledReturnButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`
