// Packages
import { Row, Col } from 'antd'
import styled from 'styled-components'

export const StyledReportContent = styled.div`
  background: #fff;
`
export const StyledDonwloadButtonsContainer = styled.div`
  padding: 0 8px;

  @media (min-width: 1024px) {
    margin-top: 30px;
  }
`
export const StyledEmptyData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
  img {
    transform: translateY(-21%);
  }
  p {
    width: 210px;
    height: 38px;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #828282;
  }
`

export const StyledReportFilters = styled(Col)``

export const StyledSelectContainer = styled(Row)`
  width: 100%;
  background-color: #001529;
  padding: 0 0 6px 0;
  @media (min-width: 576px) {
    padding: 0;
  }
`
