/* eslint-disable @typescript-eslint/no-unused-vars */
// Packages
import React, { useEffect, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { callProfileRedux } from '../../containers/ProfileContainer'

// Containers
import DownloadStockContainer from '../DownloadStockContainer'

// Components
import InventoryContent from '../../components/InventoryContent'
import InventoryFilter from '../../components/InventoryFilter'

// Types
import { InventoryArticleInterface } from '../../types/Reports/Inventory'
import { dateValueInterface } from '../../components/Storybook/DatepickerRange'

// Queries
import { GET_INVENTORY_TRACKING } from '../../graphql/queries/Reports'

// Utils
import { removeSelect } from '../../components/StoresSelect/utils'
import { getParameter, setParameter } from '../../utils/queryString'
import { setLocalStorageItem } from '../../utils/storage'
import moment from 'moment'
import { navigate } from 'gatsby'

const InventoryContainer: React.FC = () => {
  const { user_data } = useSelector(callProfileRedux)
  const [storeID, setStoreValue] = useState(getParameter('storeId'))
  const [lastReassignationDate, setLastReassignationDate] = useState('')
  const [vtexFranchise, setVtexFranchise] = useState('')
  const [vtexMarketplace, setVtexMarketplace] = useState('')
  const [startDate, setStartDate] = useState(getParameter('startDate'))
  const [endDate, setendDate] = useState(getParameter('endDate'))
  const [dateValue, setDateValue] = useState<dateValueInterface | undefined>({
    renderFrom: getParameter('startDate'),
    renderTo: getParameter('endDate')
  })
  const [inventoryTracking, setInventoryTracking] =
    useState<InventoryArticleInterface[]>()
  const [articleTracking, setArticleTracking] =
    useState<InventoryArticleInterface[]>()

  const [inventoryRefetch, { data: dataInventory, loading: loadingInventory }] =
    useLazyQuery(GET_INVENTORY_TRACKING)

  const validateStartDate = (startDate: string) => {
    if (
      lastReassignationDate &&
      moment(startDate).isSameOrBefore(lastReassignationDate)
    ) {
      setStartDate(lastReassignationDate)
    } else {
      setStartDate(moment().subtract('30', 'days').format('YYYY-MM-DD'))
    }
  }

  const getValuesToGetInventoryByStoreID = (storeID: string) => {
    const storeSelected = user_data.getProfile?.stores.filter(
      (x) => x.storeID === storeID
    )
    validateStartDate(
      storeSelected[0].lastReassignationDate !== null
        ? storeSelected[0].lastReassignationDate.split('-').reverse().join('-')
        : moment().format('YYYY-DD-MM')
    )
    setStoreValue(storeSelected[0].storeID)
    setVtexFranchise(storeSelected[0].vtexFranchise)
    setVtexMarketplace(storeSelected[0].vtexMarketplace)
    setendDate(moment().format('YYYY-MM-DD'))
    setLastReassignationDate(
      storeSelected[0].lastReassignationDate
        ? storeSelected[0].lastReassignationDate.split('-').reverse().join('-')
        : null
    )
  }

  useEffect(() => {
    if (user_data && JSON.stringify(user_data) !== '{}' && storeID) {
      getValuesToGetInventoryByStoreID(storeID)
    }
  }, [user_data, storeID, lastReassignationDate])

  useEffect(() => {
    if (startDate && vtexFranchise && vtexMarketplace) {
      inventoryRefetch({
        variables: {
          requestConfig: {
            marketplace: vtexMarketplace,
            franchise: vtexFranchise
          },
          from: startDate,
          to: endDate,
          timezone: 'UTC-6'
        },
        context: {
          clientName: 'api-instore'
        }
      })
    }
  }, [
    startDate,
    endDate,
    vtexFranchise,
    vtexMarketplace,
    lastReassignationDate
  ])

  useEffect(() => {
    if (dataInventory && !loadingInventory) {
      const dataInventoryFiltered = dataInventory.inventoryTracking.filter(
        (article: InventoryArticleInterface) => {
          return article.isOwnProduct === false
        }
      )

      const dataArticleFiltered = dataInventory.inventoryTracking.filter(
        (article: InventoryArticleInterface) => {
          return article.isOwnProduct === true
        }
      )

      setInventoryTracking(dataInventoryFiltered)
      setArticleTracking(dataArticleFiltered)
    }
  }, [dataInventory, loadingInventory])

  const handleChangeSelect = (value: string): void => {
    removeSelect()
    setParameter('storeId', value)
    setLocalStorageItem('storeID', value)
    setInventoryTracking(undefined)
    const store = user_data.getProfile?.stores?.find(
      ({ storeID }: { storeID: string }) => storeID === value
    )
    setVtexFranchise(store.vtexFranchise)
    setLastReassignationDate(store.lastReassignationDate)
    setLocalStorageItem('storeName', store?.name)
    setStoreValue(value)
  }

  const handleChangeDate = (value: any) => {
    const newStartDate = moment(value[0]).format('YYYY-MM-DD')
    const newEndDay = moment(value[1]).format('YYYY-MM-DD')
    setendDate(newEndDay)
    setStartDate(newStartDate)
    setDateValue({ renderFrom: newStartDate, renderTo: newEndDay })

    navigate(
      `/reports/inventory?storeId=${storeID}&startDate=${moment(
        value[0]
      ).format('YYYY-MM-DD')}&endDate=${moment(value[1]).format('YYYY-MM-DD')}`
    )
  }

  const disabledDate = (current) => {
    const start = moment(
      lastReassignationDate
        ? lastReassignationDate
        : moment().subtract(1, 'year'),
      'YYYY-MM-DD'
    )
    return current < start || current > moment()
  }

  return (
    <>
      <InventoryFilter
        store={storeID ? storeID : user_data.getProfile?.stores[0].storeID}
        stores={
          user_data.getProfile
            ? user_data.getProfile?.stores
            : /* istanbul ignore next */ []
        }
        handleChangeDate={handleChangeDate}
        handleChangeSelect={handleChangeSelect}
        disabledDate={(current) => disabledDate(current)}
        dateValue={dateValue}
      />
      <DownloadStockContainer />
      <InventoryContent
        loadingInventory={loadingInventory}
        inventoryTracking={inventoryTracking}
        articleTracking={articleTracking}
      />
    </>
  )
}

export default InventoryContainer
