import { DatePicker } from 'antd'
import styled from 'styled-components'
const { RangePicker } = DatePicker

export const StyledDatePickerRangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0;
`
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const StyledDatePickerRange = styled(RangePicker)`
  &&& {
    width: 95%;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #a7a5a5;

    .ant-picker-input > input {
      font-weight: 600;
      font-size: 12px;
      @media (max-width: 375px) {
        font-size: 10px;
      }
    }

    .ant-picker-input > input::placeholder {
      font-weight: 400;
    }
  }
`
