import React from 'react'
import moment from 'moment'

import {
  StyledConatnerReportByMonthsPDF,
  StyledContainerTableDetail,
  StyledTDOdd,
  StyledTD,
  StyledTRDetail
} from './styledComponent'

import {
  LandscapeOrientation,
  PDFHeaderContainer,
  TableHeadersTR
} from '../ReportComponentsPDF'
import { ProductListStack } from '../../types/Reports/Inventory'

export const TableTR: React.FC<ProductListStack> = (
  props: ProductListStack
) => (
  <>
    <StyledTD>{props.productName}</StyledTD>
    <StyledTD>{props.stock}</StyledTD>
    <StyledTD>{props.brandName}</StyledTD>
    <StyledTD>{props.categoryName}</StyledTD>
    <StyledTD>{props.measurementMultiplier}</StyledTD>
    <StyledTD>{props.measurementUnit}</StyledTD>
    <StyledTD>{props.packaging}</StyledTD>
    <StyledTD>
      {moment(props.updateDatetime).format('DD-MM-YYYY HH:mm:ss')}
    </StyledTD>
  </>
)

export const TableTROdd: React.FC<ProductListStack> = (
  props: ProductListStack
) => {
  return (
    <>
      <StyledTDOdd>{props.productName}</StyledTDOdd>
      <StyledTDOdd>{props.stock}</StyledTDOdd>
      <StyledTDOdd>{props.brandName}</StyledTDOdd>
      <StyledTDOdd>{props.categoryName}</StyledTDOdd>
      <StyledTDOdd>{props.measurementMultiplier}</StyledTDOdd>
      <StyledTDOdd>{props.measurementUnit}</StyledTDOdd>
      <StyledTDOdd>{props.packaging}</StyledTDOdd>
      <StyledTDOdd>
        {moment(props.updateDatetime).format('DD-MM-YYYY HH:mm:ss')}
      </StyledTDOdd>
    </>
  )
}

export const resortArr = (a: ProductListStack, b: ProductListStack): number => {
  if (a.stock !== b.stock) {
    return a.stock - b.stock
  }
  const value = a.productName > b.productName ? 1 : -1
  return a.productName === b.productName ? 0 : value
}
interface propsType {
  storeInformation: {
    storeID: string | undefined
    storeName: string
    partnerName: string
  }
  queryData: ProductListStack[] | undefined
}

export const ReportInventoryStockPDF = React.forwardRef(
  (
    props: propsType,
    ref?:
      | ((instance: HTMLDivElement | null) => void)
      | React.RefObject<HTMLDivElement>
      | null
      | undefined
  ) => {
    const { queryData } = props

    if (queryData) {
      queryData.sort(resortArr)
    }

    return (
      <StyledConatnerReportByMonthsPDF ref={ref}>
        <LandscapeOrientation />
        <div>
          <PDFHeaderContainer
            storeID={props.storeInformation.storeID}
            storeName={props.storeInformation.storeName}
            partnerName={props.storeInformation.partnerName}
            title="REPORTE DE PIEZAS DISPONIBLES EN EL INVENTARIO"
            withDate
          />
          <StyledContainerTableDetail>
            <table>
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                <TableHeadersTR
                  labelHeader={[
                    'Nombre',
                    'Stock',
                    'Marca',
                    'Categoría',
                    'Cantidad',
                    'Volumen',
                    'Empaquetado',
                    'Fecha de actualización'
                  ]}
                />
                {queryData?.map((row, index) => (
                  <StyledTRDetail
                    key={index}
                    styles={{ isRed: row.stock <= 0 }}
                  >
                    {index % 2 ? <TableTR {...row} /> : <TableTROdd {...row} />}
                  </StyledTRDetail>
                ))}
              </tbody>
            </table>
          </StyledContainerTableDetail>
        </div>
      </StyledConatnerReportByMonthsPDF>
    )
  }
)
