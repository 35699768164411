import styled from 'styled-components'
import { Table } from 'antd'
import {
  StyledMediaTabs,
  StyledTabHeader
} from '../LearningCategory/styledComponents'

export const StyledInventoryTabs = styled(StyledMediaTabs)`
  .ant-tabs-nav {
    height: 35px;
  }
  .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab {
      width: 50%;
      .ant-tabs-tab-btn {
        margin: 0 auto;
      }
    }
  }
`
export const CustomStyledTabHeader = styled(StyledTabHeader)`
  margin-top: 0;
`

export const IncomeHeader = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-around;
  &:first-child {
    img {
      margin-right: 10px;
    }
  }
  &:last-child {
    img {
      margin-left: 10px;
    }
  }
`

export const TableHeaderIcon = styled.img`
  width: 16px;
`

export const StyledTable = styled(Table)`
  table {
    table-layout: fixed !important;
  }
  th {
    text-align: center !important;
    background-color: #fff !important;
  }
  td {
    text-align: center;
    &:before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 1.6em;
      background-color: rgba(0, 0, 0, 0.06);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      transition: background-color 0.3s;
      content: '';
    }
  }
`

export const SytledArticleCell = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
`

export const StyledTitleCell = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 110px;
  text-align: left;

  @media (min-width: 768px) {
    text-overflow: initial;
  }
`

export const StyledSKUCell = styled.span`
  text-align: left;
`

export const StyledTotalContent = styled.span`
  display: flex;
  justify-content: center;
  svg {
    display: none;
    font-size: 16px;
    margin-top: 3px;
    margin-left: 15px;
    &:hover {
      cursor: pointer;
    }
  }
`
