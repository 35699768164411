// Packages
import React, { useEffect, useState } from 'react'
import { RightOutlined } from '@ant-design/icons'

// Styled Components
import {
  StyledMediaPane,
  StyledTabTitle
} from '../LearningCategory/styledComponents'
import {
  StyledInventoryTabs,
  IncomeHeader,
  CustomStyledTabHeader,
  TableHeaderIcon,
  StyledTable,
  SytledArticleCell,
  StyledTitleCell,
  StyledSKUCell,
  StyledTotalContent
} from './styledComponents'

// Components
import Loading from '../Shared/Loading'

// Types
import {
  InventoryArticleInterface,
  RenderedInventoryArticleInterface
} from '../../types/Reports/Inventory'

// Assets
import incomeIcon from '../../data/assets/icons/incomeIcon.svg'
import outcomeIcon from '../../data/assets/icons/outcomeIcon.svg'

interface InventoryProps {
  loadingInventory: boolean
  inventoryTracking: InventoryArticleInterface[] | undefined
  articleTracking: InventoryArticleInterface[] | undefined
}

const columns = [
  {
    title: 'ARTÍCULO',
    dataIndex: 'articleTitle',
    key: 'articleTitle',
    width: 130
  },
  {
    title: (
      <IncomeHeader>
        <TableHeaderIcon src={incomeIcon} />
        {'E'}
      </IncomeHeader>
    ),
    dataIndex: 'incomeCounter',
    key: 'incomeCounter',
    width: 70
  },
  {
    title: (
      <IncomeHeader>
        {'S'}
        <TableHeaderIcon src={outcomeIcon} />
      </IncomeHeader>
    ),
    dataIndex: 'outcomeCounter',
    key: 'outcomeCounter',
    width: 70
  },
  {
    title: 'TOTAL',
    dataIndex: 'totalCounter',
    key: 'totalCounter',
    width: 80
  }
]

const InventoryContent: React.FC<InventoryProps> = ({
  loadingInventory,
  inventoryTracking,
  articleTracking
}) => {
  const [renderedInventoryTracking, setRenderedInventoryTracking] = useState<
    RenderedInventoryArticleInterface[]
  >([])

  const [renderedArticleTracking, setRenderedArticleTracking] = useState<
    RenderedInventoryArticleInterface[]
  >([])

  useEffect(() => {
    const renderedInventoryTrackingArray: RenderedInventoryArticleInterface[] =
      []
    const renderedArticleTrackingArray: RenderedInventoryArticleInterface[] = []

    inventoryTracking?.forEach((article) => {
      renderedInventoryTrackingArray.push({
        key: article.skuId,
        articleTitle: (
          <SytledArticleCell>
            <StyledTitleCell>
              <b>{article.productName}</b>
            </StyledTitleCell>
            <br />
            <StyledSKUCell>{article.skuId}</StyledSKUCell>
          </SytledArticleCell>
        ),
        incomeCounter: `+${article.incomeCounter}`,
        outcomeCounter: `${article.outcomeCounter}`,
        totalCounter: (
          <StyledTotalContent>
            {article.totalCounter}
            <RightOutlined />
          </StyledTotalContent>
        ),
        isOwnProduct: article.isOwnProduct
      })
    })

    articleTracking?.forEach((article) => {
      renderedArticleTrackingArray.push({
        key: article.skuId,
        articleTitle: (
          <>
            <b>{article.productName}</b>
            <br />
            {article.skuId}
          </>
        ),
        incomeCounter: `+${article.incomeCounter}`,
        outcomeCounter: `${article.outcomeCounter}`,
        totalCounter: (
          <StyledTotalContent>
            {article.totalCounter}
            <RightOutlined />
          </StyledTotalContent>
        ),
        isOwnProduct: article.isOwnProduct
      })
    })

    setRenderedInventoryTracking(renderedInventoryTrackingArray)
    setRenderedArticleTracking(renderedArticleTrackingArray)
  }, [inventoryTracking, articleTracking])

  const inventoryTab = (
    <CustomStyledTabHeader>
      <StyledTabTitle>Inventario</StyledTabTitle>
    </CustomStyledTabHeader>
  )

  const articlesTab = (
    <CustomStyledTabHeader>
      <StyledTabTitle>Mis artículos</StyledTabTitle>
    </CustomStyledTabHeader>
  )

  const inventoryContent = (
    <>
      <StyledTable
        dataSource={renderedInventoryTracking}
        columns={columns}
        pagination={false}
      />
    </>
  )

  const articlesContent = (
    <>
      <StyledTable
        dataSource={renderedArticleTracking}
        columns={columns}
        pagination={false}
      />
    </>
  )

  return (
    <>
      <StyledInventoryTabs defaultActiveKey="1">
        <StyledMediaPane tab={inventoryTab} key="1">
          {loadingInventory ? <Loading /> : inventoryContent}
        </StyledMediaPane>
        <StyledMediaPane tab={articlesTab} key="2">
          {articlesContent}
        </StyledMediaPane>
      </StyledInventoryTabs>
    </>
  )
}

export default InventoryContent
