import { useLazyQuery } from '@apollo/client'
import { GET_PRODUCT_LIST } from '../../graphql/queries/Reports'
import { useEffect, useState } from 'react'
import { ProductListStack } from '../../types/Reports/Inventory'

interface GetProductListResponse {
  productListV2: {
    storeProductList: ProductListStack[]
    token: string | null
  }
}

interface GetProductListVariables {
  requestConfig: {
    marketplace: string
    franchise: string
    forceUpdate?: boolean
  }
  ownProduct?: boolean
  skuId?: string
  eanId?: string
  token?: string
}

interface UseGetAllPagesProductListResponse {
  data: ProductListStack[] | undefined
  loading: boolean
  error: any
}

export const useGetAllPagesProductList = (): [
  (variablesInput: GetProductListVariables) => void,
  UseGetAllPagesProductListResponse
] => {
  const [stockRefetch, responseProductList] = useLazyQuery<
    GetProductListResponse,
    GetProductListVariables
  >(GET_PRODUCT_LIST, {
    context: {
      clientName: 'api-instore'
    },
    fetchPolicy: 'cache-and-network'
  })

  const [productListStack, setProductListStack] = useState<
    ProductListStack[] | undefined
  >(undefined)

  const [loadingResponse, setLoadingResponse] = useState<boolean>(false)

  const [variables, setVariables] = useState<
    GetProductListVariables | undefined
  >(undefined)

  const callBackFetch = (variablesInput: GetProductListVariables) => {
    setProductListStack([])
    setLoadingResponse(true)
    stockRefetch({ variables: variablesInput })
    setVariables(variablesInput)
  }

  useEffect(() => {
    const { data, loading, error } = responseProductList
    if (data && !loading && !error && variables) {
      const { productListV2 } = data
      const productListStackNow = productListV2.storeProductList
      const previousStack = productListStack ? productListStack : []
      const stack = [...previousStack, ...productListStackNow]
      setProductListStack(stack)

      if (productListV2.token) {
        const variablesClone = { ...variables }
        variablesClone['token'] = productListV2.token
        stockRefetch({ variables: variablesClone })
      }
      if (!productListV2.token) {
        setLoadingResponse(false)
      }
    }
  }, [responseProductList])

  return [
    callBackFetch,
    {
      data: !loadingResponse ? productListStack : undefined,
      loading: loadingResponse,
      error: responseProductList.error
    }
  ]
}
