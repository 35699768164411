import React, { useEffect, useRef, useState } from 'react'
import { useGetAllPagesProductList } from '../../hooks/useLoopGetStock'
import ModalResult from '../../components/Shared/ModalResult'
import CustomButton from '../../components/Storybook/customButton'
import { printInventoryStockExcel } from '../../utils/excelInventoryFile'
import { customEventGA } from '../../utils/googleAnalitycs'
import DownloadInventoryStockButtons from '../../components/DownloadInventoryStockButtons'
import { ReportInventoryStockPDF } from '../../components/ReportInventoryStockPDF'
import ModalResponse from '../../components/Shared/ModalResponse'
import { useReactToPrint } from 'react-to-print'
import { getParameter } from '../../utils/queryString'
import { useSelector } from 'react-redux'
import { callProfileRedux } from '../ProfileContainer'

interface StoreData {
  storeID: string
  name: string
  vtexMarketplace: string
  vtexFranchise: string
}

const DownloadStockContainer: React.FC = () => {
  const storeID = getParameter('storeId')
  const { user_data } = useSelector(callProfileRedux)
  const [stockRefetch, { data, loading, error }] = useGetAllPagesProductList()
  const [store, setStore] = useState<StoreData | undefined>(undefined)

  const componentRef = useRef(null)
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false)
  const [typeModal, setTypeModal] = useState<'PDF' | 'Excel' | undefined>(
    undefined
  )

  useEffect(() => {
    if (user_data && JSON.stringify(user_data) !== '{}' && storeID) {
      const storeSelected = user_data.getProfile?.stores.filter(
        (x) => x.storeID === storeID
      )[0] as StoreData
      setStore(storeSelected)
    }
  }, [user_data, storeID])

  const onClickDonwload = (): void => {
    stockRefetch({
      requestConfig: {
        marketplace: store?.vtexMarketplace || '',
        franchise: store?.vtexFranchise || '',
        forceUpdate: true
      }
    })
    setIsVisibleModal(false)
  }

  const modalContent = (
    <ModalResult
      key="cancel-edit-modal"
      status={'warning'}
      subTitle={`¿Deseas continuar con la descarga del archivo ${
        typeModal === 'PDF' ? typeModal : 'Excel'
      }?`}
      extra={
        <div key={'ModalResultKeyExtra'}>
          <CustomButton
            id="success-sales-report-download-file"
            name="successSalesReportDownloadFile"
            dataTestId="data-testid-success-sales-report-download-file"
            label="Aceptar"
            className="save-button-sales-report-download-file"
            onClick={onClickDonwload}
          />
          <CustomButton
            id="cancel-sales-report-download-file"
            name="cancelSalesReportDownloadFile"
            dataTestId="data-testid-cancel-sales-report-download-file"
            label="Cancelar"
            className="cancel-button-sales-report-download-file"
            onClick={() => setIsVisibleModal(false)}
          />
        </div>
      }
    />
  )

  useEffect(() => {
    if (data && !loading && !error) {
      printFile()
    }
  }, [data, loading, error])

  const printFile = () => {
    if (typeModal === 'Excel') {
      printInventoryStockExcel({ content: () => componentRef.current })
    }
    if (typeModal === 'PDF') {
      printInventoryStockPDF()
    }
    setTypeModal(undefined)
  }

  const printInventoryStockPDF = useReactToPrint({
    content: () => componentRef.current
  })

  const handleModal = (type: 'PDF' | 'Excel'): void => {
    customEventGA({
      eventCategory: 'Reports',
      eventAction: 'Download InventoryStock Report ' + type,
      eventLabel: 'InventoryContainer'
    })
    setIsVisibleModal(true)
    setTypeModal(type)
  }

  return (
    <div>
      {store && (
        <DownloadInventoryStockButtons
          onClickDonwload={handleModal}
          disabled={loading}
        />
      )}

      <div style={{ display: 'none' }}>
        <ReportInventoryStockPDF
          storeInformation={{
            storeID: store?.storeID,
            storeName: store?.name || '',
            partnerName: `${user_data?.getProfile?.name} ${user_data?.getProfile?.familyName}`
          }}
          queryData={data}
          ref={componentRef}
        />
      </div>

      <ModalResponse
        isModalVisible={isVisibleModal}
        content={modalContent}
        onCancel={() => {
          setIsVisibleModal(false)
        }}
      />
    </div>
  )
}

export default DownloadStockContainer
