// Packages
import React from 'react'
import { Col, Row } from 'antd'

// Styled Components
import { StyledSeparator } from './styledComponents'

// Components
import { ButtonDownloadFile } from '../Storybook/ButtonDownloadFile'

// Assets
import imagePDF from '../../data/assets/icons/pdf.png'
import imageExcel from '../../data/assets/icons/excel.png'

export interface DownloadInventoryStockProps {
  onClickDonwload: (typeModal: 'PDF' | 'Excel') => void
  disabled?: boolean
}

const DownloadInventoryStock: React.FC<DownloadInventoryStockProps> = ({
  onClickDonwload,
  disabled
}) => {
  return (
    <>
      <StyledSeparator />
      <Row gutter={16} justify="center">
        <Col lg={8} md={8} sm={10} xs={10}>
          <ButtonDownloadFile
            text="Piezas disponibles"
            icon={imagePDF}
            onClick={() => onClickDonwload('PDF')}
            disabled={disabled}
            alignment={'a-left'}
          />
        </Col>
        <Col lg={8} md={8} sm={10} xs={10}>
          <ButtonDownloadFile
            text="Piezas disponibles"
            icon={imageExcel}
            onClick={() => onClickDonwload('Excel')}
            disabled={disabled}
            alignment={'a-right'}
          />
        </Col>
      </Row>
    </>
  )
}

export default DownloadInventoryStock
