export const setSelect = (defaultSelect: string | undefined): void => {
  const optionSelect = document.querySelector(
    `.rc-virtual-list-holder-inner [title="${defaultSelect}"]`
  )
  optionSelect?.classList.add('ant-select-item-option-selected')
}
export const removeSelect = (): void => {
  const optionDeselect = document.querySelector(
    '.rc-virtual-list-holder-inner .ant-select-item-option-selected'
  )
  optionDeselect?.classList.remove('ant-select-item-option-selected')
}
