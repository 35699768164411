// Packages
import React from 'react'
import { navigate } from 'gatsby'
import { Col } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { Moment } from 'moment'

// Styled Components
import {
  StyledFilterContent,
  StyledReturnButtonContent,
  StyledDatePickerContent,
  StyledReturnButton
} from './styledComponents'

// Components
import {
  DatePickerRange,
  dateValueInterface
} from '../Storybook/DatepickerRange'
import { StyledSelectContainer } from '../ReportContent/styledComponents'
import { StoresSelect } from '../StoresSelect'

interface InventoryFilterProps {
  store: string
  stores: Array<StoreTypes.stores>
  handleChangeSelect: (value: string) => void
  handleChangeDate: (value: string) => void
  disabledDate: ((current: Moment) => boolean) | undefined
  dateValue?: dateValueInterface
}

const InventoryFilter: React.FC<InventoryFilterProps> = ({
  store,
  stores,
  handleChangeSelect,
  handleChangeDate,
  disabledDate,
  dateValue
}) => {
  return (
    <>
      <StyledSelectContainer align="middle">
        <Col xs={2} />
        <Col xs={2} />
        <Col xs={24} sm={10}>
          <StoresSelect
            onChange={handleChangeSelect}
            store={store}
            stores={stores}
          />
        </Col>
        <Col xs={2} />
      </StyledSelectContainer>

      <StyledFilterContent>
        <StyledReturnButtonContent>
          <StyledReturnButton
            onClick={() => {
              navigate('/reports')
            }}
          >
            <LeftOutlined />
            {'REPORTES'}
          </StyledReturnButton>
        </StyledReturnButtonContent>
        <StyledDatePickerContent>
          <DatePickerRange
            format="YYYY-MM-DD"
            name="DatePickerSalesReport"
            id="datepicker-sales-report"
            dataTestId="datepicker-sales-report"
            changeDate={handleChangeDate}
            disabledDate={disabledDate}
            dateValue={dateValue}
          />
        </StyledDatePickerContent>
      </StyledFilterContent>
    </>
  )
}

export default InventoryFilter
